// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Page from "../layouts/Page.bs.js";
import * as Util from "./util.bs.js";
import * as NextSeo from "next-seo";
import * as LandingPage from "../layouts/LandingPage.bs.js";
import * as ServerError from "../components/pages/error/ServerError.bs.js";
import * as BusinessPage from "../layouts/BusinessPage.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function Pages(props) {
  var content = props.content;
  var router = props.router;
  var match = router.route;
  var exit = 0;
  switch (match) {
    case "/404" :
    case "/404/" :
        exit = 6;
        break;
    case "/_error" :
        return JsxRuntime.jsx(Page.make, {
                    children: JsxRuntime.jsx(ServerError.make, {})
                  });
    case "/about" :
    case "/about/" :
        exit = 11;
        break;
    case "/about/adrian-turner" :
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: "Prezzee Digital Gift Cards | Adrian Turner",
                            description: "Learn more about Adrian Turner.",
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case "/about/claire-morris" :
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: "Prezzee Digital Gift Cards | Claire Morris",
                            description: "Learn more about Claire Morris.",
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case "/about/cliff-rosenberg" :
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: "Prezzee Digital Gift Cards | Cliff Rosenberg",
                            description: "Learn more about Cliff Rosenberg.",
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case "/about/edward-piazza" :
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: "Prezzee Digital Gift Cards | Edward Piazza",
                            description: "Learn more about Edward Piazza.",
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case "/about/philip-corne" :
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: "Prezzee Digital Gift Cards | Philip Corne",
                            description: "Learn more about Philip Corne.",
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case "/about/roger-hatem" :
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: "Prezzee Digital Gift Cards | Roger Hatem",
                            description: "Learn more about Roger Hatem.",
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case "/about/ruth-newfield" :
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: "Prezzee Digital Gift Cards | Ruth Newfield",
                            description: "Learn more about Ruth Newfield.",
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case "/about/shaun-bonett" :
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: "Prezzee Digital Gift Cards | Shaun Bonett",
                            description: "Learn more about Shaun Bonett.",
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case "/about/steve-bonett" :
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: "Prezzee Digital Gift Cards | Steve Bonett",
                            description: "Learn more about Steve Bonett.",
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case "/about/susan-omalley" :
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: "Prezzee Digital Gift Cards | Susan O'Malley",
                            description: "Learn more about Susan O'Malley.",
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case "/about/tony-karp" :
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: "Prezzee Digital Gift Cards | Tony Karp",
                            description: "Learn more about Tony Karp.",
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case "/business" :
    case "/business/" :
    case "/business/faq" :
    case "/business/faq/" :
        return JsxRuntime.jsx(BusinessPage.make, {
                    children: content
                  });
    case "/business/gift-cards" :
    case "/business/gift-cards/[pid]" :
        exit = 4;
        break;
    case "/contact" :
        var match$1 = Util.EnvUtil.currentCountry;
        var tmp;
        if (match$1.TAG === /* Ok */0) {
          switch (match$1._0) {
            case /* GreatBritain */1 :
                tmp = "Contact Us | Prezzee UK";
                break;
            case /* UnitedStates */2 :
                tmp = "Contact Us | Prezzee US";
                break;
            case /* NewZealand */3 :
                tmp = "Contact Us | Prezzee NZ";
                break;
            case /* Australia */0 :
            case /* Canada */4 :
                tmp = "Contact Us | Prezzee";
                break;
            
          }
        } else {
          tmp = "Contact Us | Prezzee";
        }
        var match$2 = Util.EnvUtil.currentCountry;
        var tmp$1;
        if (match$2.TAG === /* Ok */0) {
          switch (match$2._0) {
            case /* UnitedStates */2 :
                tmp$1 = "Have a question about our gift cards? We're available seven days a week. Get in touch! We'd love to hear from you. Click here to find out more.";
                break;
            case /* Australia */0 :
            case /* GreatBritain */1 :
            case /* NewZealand */3 :
            case /* Canada */4 :
                tmp$1 = "Contact us at Prezzee. Please ask any questions about eGift cards, leave a comment or suggestion with us. Contact us via email or call our help desk today.";
                break;
            
          }
        } else {
          tmp$1 = "Contact us at Prezzee. Please ask any questions about eGift cards, leave a comment or suggestion with us. Contact us via email or call our help desk today.";
        }
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: tmp,
                            description: tmp$1,
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case "/error" :
    case "/error/" :
        exit = 5;
        break;
    case "/exchange/card/[...pid]" :
        return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            noindex: true,
                            nofollow: true
                          }),
                      content
                    ]
                  });
    case "/forgot-email-address" :
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: "Forgot Email Address | Prezzee",
                            description: "Forgot your Prezzee email address? Recover your email address with Prezzee. Read more about Prezzee gift cards. Shop online today.",
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ],
                    mainClassName: Page.Styles.fullHeightMainContainer,
                    footerInFlexibleContainer: false
                  });
    case "/forgot-password" :
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: "Forgot Password | Prezzee",
                            description: "Forgot your Prezzee password? Recover your password with Prezzee. Read more about Prezzee gift cards. Shop online today.",
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ],
                    mainClassName: Page.Styles.fullHeightMainContainer,
                    footerInFlexibleContainer: false
                  });
    case "/international" :
        var match$3 = Util.EnvUtil.currentCountry;
        var tmp$2;
        if (match$3.TAG === /* Ok */0) {
          switch (match$3._0) {
            case /* GreatBritain */1 :
                tmp$2 = "International Gift Cards | Prezzee UK";
                break;
            case /* UnitedStates */2 :
                tmp$2 = "eGift Card | Prezzee US";
                break;
            case /* NewZealand */3 :
                tmp$2 = "International Gift Cards | Prezzee NZ";
                break;
            case /* Australia */0 :
            case /* Canada */4 :
                tmp$2 = "International Gift Cards | Prezzee";
                break;
            
          }
        } else {
          tmp$2 = "International Gift Cards | Prezzee";
        }
        var match$4 = Util.EnvUtil.currentCountry;
        var tmp$3;
        if (match$4.TAG === /* Ok */0) {
          switch (match$4._0) {
            case /* GreatBritain */1 :
                tmp$3 = "Prezzee digital gift cards are now available for overseas country brands including USA, AUS and NZ. Click here for our Prezzee International gift cards.";
                break;
            case /* UnitedStates */2 :
                tmp$3 = "The Prezzee eGift Card makes buying, sending, storing and redeeming digital Gift Cards easier than ever before! Shop online today for the perfect present!";
                break;
            case /* NewZealand */3 :
                tmp$3 = "Prezzee digital gift cards are now available for overseas country brands including USA, AUS and UK. Click here for our Prezzee International gift cards.";
                break;
            case /* Australia */0 :
            case /* Canada */4 :
                tmp$3 = "Prezzee digital gift cards are now available for overseas country brands including USA, UK and NZ. Learn more about Prezzee International.";
                break;
            
          }
        } else {
          tmp$3 = "Prezzee digital gift cards are now available for overseas country brands including USA, UK and NZ. Learn more about Prezzee International.";
        }
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: tmp$2,
                            description: tmp$3,
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case "/business/landing/[landing]" :
    case "/landing/[landing]" :
        return JsxRuntime.jsx(LandingPage.make, {
                    children: content
                  });
    case "/login" :
    case "/login/" :
        exit = 7;
        break;
    case "/delete-account" :
    case "/my-details" :
        exit = 9;
        break;
    case "/gift/[...pid]" :
    case "/gift/open-ndl/[uid]" :
    case "/gift/open-ndl/[uid]/auth" :
    case "/gift/open-ndl/[uid]/login" :
    case "/gift/open-ndl/[uid]/signup" :
    case "/gift/open/[uid]" :
    case "/gift/open/[uid]/auth" :
    case "/gift/open/[uid]/login" :
    case "/gift/open/[uid]/signup" :
    case "/partner/exchange" :
        exit = 12;
        break;
    case "/pending" :
        return JsxRuntime.jsx(Page.make, {
                    children: content,
                    mainClassName: "flex"
                  });
    case "/register" :
    case "/register/" :
        exit = 8;
        break;
    case "/reminders" :
    case "/reminders/" :
        exit = 10;
        break;
    case "/orders" :
    case "/orders/[uid]" :
    case "/reset-password/[uid]" :
        exit = 3;
        break;
    case "/store" :
        var match$5 = Util.EnvUtil.currentCountry;
        var tmp$4;
        if (match$5.TAG === /* Ok */0) {
          switch (match$5._0) {
            case /* GreatBritain */1 :
                tmp$4 = "Digital Gift Cards | Prezzee UK";
                break;
            case /* UnitedStates */2 :
                tmp$4 = "Buy Digital Gift Cards | Prezzee US";
                break;
            case /* NewZealand */3 :
                tmp$4 = "Digital Gift Cards | Prezzee NZ";
                break;
            case /* Australia */0 :
            case /* Canada */4 :
                tmp$4 = "Our Digital Gift Cards | Prezzee";
                break;
            
          }
        } else {
          tmp$4 = "Our Digital Gift Cards | Prezzee";
        }
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: tmp$4,
                            description: "Check out our Prezzee store to access hundreds of gift cards for loads of different retailers. You can buy digital gift cards and vouchers from home. See more.",
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case "/" :
    case "/board" :
    case "/confirmed" :
    case "/news" :
    case "/policies" :
    case "/team" :
        exit = 1;
        break;
    case "/terms-promotions" :
        var match$6 = Util.EnvUtil.currentCountry;
        var tmp$5;
        if (match$6.TAG === /* Ok */0) {
          switch (match$6._0) {
            case /* GreatBritain */1 :
                tmp$5 = "Promotion Terms & Conditions | Prezzee UK";
                break;
            case /* UnitedStates */2 :
                tmp$5 = "Buy Gift Cards | Prezzee US";
                break;
            case /* NewZealand */3 :
                tmp$5 = "Promotion Terms & Conditions | Prezzee NZ";
                break;
            case /* Australia */0 :
            case /* Canada */4 :
                tmp$5 = "Promotion Terms & Conditions | Prezzee";
                break;
            
          }
        } else {
          tmp$5 = "Promotion Terms & Conditions | Prezzee";
        }
        var match$7 = Util.EnvUtil.currentCountry;
        var tmp$6;
        if (match$7.TAG === /* Ok */0) {
          switch (match$7._0) {
            case /* GreatBritain */1 :
                tmp$6 = "Read more about our Prezzee Terms and Conditions for our digital gift cards. Learn more about Prezzee and shop online today.";
                break;
            case /* UnitedStates */2 :
                tmp$6 = "Give a gift you know they'll love when you buy gift cards from Prezzee. Check out our complete range of gift cards from hundreds of top brands. Give today!";
                break;
            case /* Australia */0 :
            case /* NewZealand */3 :
            case /* Canada */4 :
                tmp$6 = "View our range of Prezzee eGift Cards Promotions Terms and Conditions for promotions and competitions. Shop online for Prezzee eGift cards today.";
                break;
            
          }
        } else {
          tmp$6 = "View our range of Prezzee eGift Cards Promotions Terms and Conditions for promotions and competitions. Shop online for Prezzee eGift cards today.";
        }
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: tmp$5,
                            description: tmp$6,
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case "/terms-promotions/[...pid]" :
        return content;
    case "/history/[uid]" :
    case "/wallet" :
        exit = 2;
        break;
    default:
      return JsxRuntime.jsx(Page.make, {
                  children: content
                });
  }
  switch (exit) {
    case 2 :
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            noindex: true,
                            nofollow: true
                          }),
                      content
                    ]
                  });
    case 4 :
        return JsxRuntime.jsx(BusinessPage.make, {
                    children: content,
                    mainClassName: Page.Styles.fullHeightMainContainer
                  });
    case 5 :
        return JsxRuntime.jsx(Page.make, {
                    children: JsxRuntime.jsx(ServerError.make, {})
                  });
    case 1 :
    case 6 :
        return JsxRuntime.jsx(Page.make, {
                    children: content
                  });
    case 7 :
        var match$8 = Util.EnvUtil.currentCountry;
        var tmp$7;
        if (match$8.TAG === /* Ok */0) {
          switch (match$8._0) {
            case /* GreatBritain */1 :
                tmp$7 = "Sign In | Prezzee UK";
                break;
            case /* UnitedStates */2 :
                tmp$7 = "Sign In| Online Gifts & Vouchers | Prezzee US";
                break;
            case /* NewZealand */3 :
                tmp$7 = "Sign In | Prezzee NZ";
                break;
            case /* Australia */0 :
            case /* Canada */4 :
                tmp$7 = "Sign In | Prezzee";
                break;
            
          }
        } else {
          tmp$7 = "Sign In | Prezzee";
        }
        var match$9 = Util.EnvUtil.currentCountry;
        var tmp$8;
        if (match$9.TAG === /* Ok */0) {
          switch (match$9._0) {
            case /* UnitedStates */2 :
                tmp$8 = "Sign in to your Prezzee account to manage your gift cards, get offers and send gifts even faster. Log in today for the best present!";
                break;
            case /* Australia */0 :
            case /* GreatBritain */1 :
            case /* NewZealand */3 :
            case /* Canada */4 :
                tmp$8 = "Prezzee makes sending eGift Cards instant, easy and it's free! Sign in to your account with Prezzee digital gift cards. Shop online today.";
                break;
            
          }
        } else {
          tmp$8 = "Prezzee makes sending eGift Cards instant, easy and it's free! Sign in to your account with Prezzee digital gift cards. Shop online today.";
        }
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: tmp$7,
                            description: tmp$8,
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ],
                    mainClassName: Page.Styles.fullHeightMainContainer,
                    footerInFlexibleContainer: false
                  });
    case 8 :
        var match$10 = Util.EnvUtil.currentCountry;
        var tmp$9;
        if (match$10.TAG === /* Ok */0) {
          switch (match$10._0) {
            case /* GreatBritain */1 :
                tmp$9 = "Register Your Account | Prezzee UK";
                break;
            case /* UnitedStates */2 :
                tmp$9 = "Register | Online Gifts & Vouchers | Prezzee US";
                break;
            case /* NewZealand */3 :
                tmp$9 = "Register Your Account | Prezzee NZ";
                break;
            case /* Australia */0 :
            case /* Canada */4 :
                tmp$9 = "Register Your Account | Prezzee";
                break;
            
          }
        } else {
          tmp$9 = "Register Your Account | Prezzee";
        }
        var match$11 = Util.EnvUtil.currentCountry;
        var tmp$10;
        if (match$11.TAG === /* Ok */0) {
          switch (match$11._0) {
            case /* UnitedStates */2 :
                tmp$10 = "Register with Prezzee to make buying, sending, storing and redeeming digital Gift Cards easier than ever before! Shop online today for the best present!";
                break;
            case /* Australia */0 :
            case /* GreatBritain */1 :
            case /* NewZealand */3 :
            case /* Canada */4 :
                tmp$10 = "Prezzee makes sending eGift Cards instant, easy and it's free! Read more about how to register your account with Prezzee digital gift cards. Shop online today.";
                break;
            
          }
        } else {
          tmp$10 = "Prezzee makes sending eGift Cards instant, easy and it's free! Read more about how to register your account with Prezzee digital gift cards. Shop online today.";
        }
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: tmp$9,
                            description: tmp$10,
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ],
                    mainClassName: Page.Styles.fullHeightMainContainer,
                    footerInFlexibleContainer: false
                  });
    case 3 :
    case 9 :
    case 10 :
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            noindex: true,
                            nofollow: true
                          }),
                      content
                    ],
                    mainClassName: Page.Styles.fullHeightMainContainer,
                    footerInFlexibleContainer: false
                  });
    case 11 :
        var match$12 = Util.EnvUtil.currentCountry;
        var tmp$11;
        if (match$12.TAG === /* Ok */0) {
          switch (match$12._0) {
            case /* GreatBritain */1 :
                tmp$11 = "About Us | Prezzee UK";
                break;
            case /* UnitedStates */2 :
                tmp$11 = "Digital Gift Cards | Online Gifts & Vouchers | Prezzee US";
                break;
            case /* NewZealand */3 :
                tmp$11 = "About Us | Prezzee NZ";
                break;
            case /* Australia */0 :
            case /* Canada */4 :
                tmp$11 = "About Us | Prezzee";
                break;
            
          }
        } else {
          tmp$11 = "About Us | Prezzee";
        }
        return JsxRuntime.jsxs(Page.make, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            title: tmp$11,
                            description: "Prezzee digital gift cards makes buying, sending, storing and redeeming easier than ever before! Learn more about Prezzee eGift cards today.",
                            canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                            languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath)
                          }),
                      content
                    ]
                  });
    case 12 :
        return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx(NextSeo.NextSeo, {
                            noindex: true,
                            nofollow: true
                          }),
                      content
                    ]
                  });
    
  }
}

var Head;

var Script;

var make = Pages;

export {
  Head ,
  Script ,
  make ,
}
/* Page Not a pure module */
