import {
  Context,
  FeatureDefinition,
  GrowthBook,
  GrowthBookProvider as OriginalGrowthbookProvider,
  useFeatureValue,
  useGrowthBook,
} from '@growthbook/growthbook-react';
import { ReactNode, useEffect, useMemo } from 'react';

export type Features = {
  'pplus-odd-denom': boolean;
  'account-deletion': boolean;
  'business-campaign-redeem-code-cta': boolean;
  'claim-tree-gift': boolean;
};

const defaultValues: { [key in keyof Features]: Features[key] } = {
  'pplus-odd-denom': false,
  'account-deletion': false,
  'business-campaign-redeem-code-cta': false,
  'claim-tree-gift': false,
};

export type CustomAttributes = {
  deviceId?: string;
  id?: string;
  email?: string;
  emailHashed?: string;
  country?: string;
};

export type FeatureDefinitions = Partial<Record<keyof Features, FeatureDefinition<Features[keyof Features]>>>;

export type GrowthbookOptions = Omit<Context, 'attributes' | 'url' | 'features'> & {
  attributes?: CustomAttributes;
  features?: FeatureDefinitions;
};

type ConsumerGrowthbook = GrowthBook<Features>;

type GrowthbookProviderProps = GrowthbookOptions & {
  children: ReactNode;
};

export const GrowthbookFeatureProvider = ({
  children,
  features,
  attributes,
  ...growthbookOptions
}: GrowthbookProviderProps) => {
  const growthbook: ConsumerGrowthbook = useMemo(
    () =>
      new GrowthBook({
        ...growthbookOptions,
        features,
        attributes,
        trackingCallback: (experiment, result) => {
          analytics.track('Experiment Viewed', {
            experimentId: experiment.key,
            variationId: result.key,
          });
        },
      }),
    []
  );

  useEffect(() => {
    if (growthbook.getFeatures() !== features && features) {
      growthbook.setFeatures(features);
    }
  }, [features]);

  useEffect(() => {
    if (growthbook.getAttributes() !== attributes && attributes) {
      growthbook.setAttributes(attributes);
    }
  }, [attributes]);

  return <OriginalGrowthbookProvider growthbook={growthbook}>{children}</OriginalGrowthbookProvider>;
};

const useGrowthbookFeatureValue = <TKey extends keyof Features>(key: TKey) => {
  const defaultValue = defaultValues[key];
  return useFeatureValue<typeof defaultValue>(key, defaultValue) as Features[TKey];
};

const useGrowthBookReady = () => {
  const gb = useGrowthBook();
  return gb.ready;
};
export { useGrowthBookReady, useGrowthbookFeatureValue };
