import logger from 'utils/logger';

import { DraftOrderPayload } from './../../models/payment.model';
import { DraftOrderResponse } from 'models/payment.model';
import { createRequest, RequestParams } from 'api/helpers';
import { camelKeys } from 'js-convert-case';
import { PurchaseKind, PurchaseType } from 'models/purchaseForm.model';
import handleErrors from 'api/handleErrors';

function getAPIFromPurchaseKindAndType(purchaseKind: PurchaseKind, purchaseType: PurchaseType): string | null {
  const GUEST_CHECKOUT_URL = 'api/v3/gifts-guest/';
  const USER_CHECKOUT_FOR_FRIEND_URL = 'api/v3/gifts/';
  const USER_CHECKOUT_FOR_SELF_URL = 'api/v3/cards/';

  if (purchaseKind === 'guest') {
    return GUEST_CHECKOUT_URL;
  } else if (purchaseKind === 'user') {
    // might need to add 'anonymous' / 'santa'
    if (purchaseType === 'friend') return USER_CHECKOUT_FOR_FRIEND_URL;
    if (purchaseType === 'self') return USER_CHECKOUT_FOR_SELF_URL;
  }
  return null;
}

const createDraftOrder = async (
  payload: DraftOrderPayload,
  purchaseType: PurchaseType,
  purchaseKind: PurchaseKind,
  isLayer: boolean
): Promise<DraftOrderResponse> => {
  const route = getAPIFromPurchaseKindAndType(purchaseKind, purchaseType);

  let params: RequestParams = {
    body: JSON.stringify({ ...payload, ...(isLayer && { is_layer: true }) }),
    method: 'POST',
    mode: 'cors',
    logRequest: true,
    withAuth: true,
    withSecurityToken: true,
  };

  if (purchaseKind === 'user') params.withAuth = true;

  return createRequest(route, params)
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }) as DraftOrderResponse);
};

export default createDraftOrder;
