import Head from 'next/head';
import Script from 'next/script';
import React, { createContext, useState } from 'react';

type AdaSupportContextType = {
  hide?: () => void;
  show?: () => void;
};

const AdaSupportContext = createContext<AdaSupportContextType>({} as AdaSupportContextType);

export const AdaSupportProvider = ({ children }) => {
  const [hidden, setHidden] = useState(false);

  const hide = () => setHidden(true);
  const show = () => setHidden(false);

  return (
    <AdaSupportContext.Provider value={{ hide, show }}>
      <Script
        async
        src="https://static.ada.support/embed2.js"
        id="__ada"
        data-handle="prezzee-gen"
        strategy="lazyOnload"
      />
      {hidden && (
        <Head>
          <style>{`#ada-entry { display: none; }`}</style>
        </Head>
      )}

      {children}
    </AdaSupportContext.Provider>
  );
};

export const useAdaSupport = () => {
  const ctx = React.useContext(AdaSupportContext);

  if (!ctx) {
    throw new Error('useAdaSupport must be used within a AdaSupportProvider');
  }

  return ctx;
};
