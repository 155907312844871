export const DAILY_LIMIT_ERROR =
  'Error: Sorry, we could not process your order. It is exceeding your daily purchase limit.';
export const SERVER_FRAUD_ERROR = 'Server Fraud Error';

export const SSR_PAGE_CACHE = 'public, max-age=600, stale-while-revalidate';
export const PRODUCT_SSR_PAGE_CACHE = 'public, max-age=60, stale-while-revalidate';
export const NO_CACHE = 'private, no-cache, no-store, max-age=0, must-revalidate';

export const SESSION_STORAGE_KEYS = {
  unwrappingLoginFlowGiftPin: 'prezzee.unwrappingLoginFlow.giftPin',
  recipientAuthFlowIsFromDecisionPage: 'prezzee.recipientAuthFlow.isFromDecisionPage',
  recipientAuthFlowIsSchemeCard: 'prezzee.recipientAuthFlow.isSchemeCard',
} as const;
