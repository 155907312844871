import { camelKeys } from 'js-convert-case';
import { createRequest } from 'api/helpers';
import handleErrors from 'api/handleErrors';
import { GiftTheme } from 'models/gift.model';

export interface GiftPreviewCard {
  isLayer: boolean;
  skuUid: string;
  skuDisplayName: string;
  originalBalance: string;
  originalBalanceCurrency: string;
  themeUid: string;
  themeKeyColour: string;
  themeTextColour: string;
  themeImage: string;
  donation: boolean;
  cardLabel: string;
}

export interface GiftPreviewResonse {
  status: string;
  message: string;
  recipientName: string;
  recipientEmail?: string;
  senderName: string;
  senderEmail: string;
  senderLogoUrl?: string;
  senderType: string;
  unwrappingAuthFlowV2: boolean;
  card: GiftPreviewCard;
  theme: GiftTheme;
  media: string[];
  relatedGiftingOccasions: string[];
  giphyId: string;
  giphyUrl: string;
  isSenderDeleted: boolean;
  shouldShowUnwrap: boolean;
}

/**
 * Request to get draft order gift preview data (used in preview functionality in purchase flow).
 * @param orderNumber - The order number
 * @param itemNumber - The item number in the order (used for multi purchase orders, starts from 0)
 * @returns The response from the gift preview API
 */
const giftPreview = (orderNumber: string, itemNumber: number = 0): Promise<GiftPreviewResonse> =>
  createRequest(`api/v3/gift/preview/${orderNumber}/${itemNumber}/`, {
    withMagicUserAgent: true,
    withAuth: true,
  })
    .then(handleErrors)
    .then(data => camelKeys(data, { recursive: true, recursiveInArray: true }) as GiftPreviewResonse);

export default giftPreview;
